import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';

import { Page, Section } from 'src/components';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Markdown } from 'src/kit';

export const query = graphql`
  query CommunityPostQuery($id: String) {
    graphCmsInterview(id: { eq: $id }) {
      id
      title
      body
      summary
      topics
      verticals
      personas
      date
      people {
        firstName
        lastName
        twitter
        avatar {
          gatsbyImageData(layout: FIXED, placeholder: BLURRED, width: 100, aspectRatio: 1)
        }
      }
      image {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, width: 2000, aspectRatio: 1.77)
      }
      quote {
        body
        people {
          id
          firstName
          lastName
          position
          location
        }
      }
    }
  }
`;

const SPost = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  > div {
    box-sizing: border-box;
    &:last-child {
      width: 40%;
      padding-left: calc(var(--side-margin) / 2);
      img {
        max-width: 100%;

        height: auto;
      }
    }
    &:first-child {
      padding-right: calc(var(--side-margin) / 2);
      padding-top: calc(var(--side-margin) / 2);
      width: 60%;
    }
  }
  @media (max-width: 800px) {
    display: flex;
    flex-direction: column-reverse;
    > div {
      box-sizing: border-box;
      &:last-child {
        width: 100%;
        padding-left: 0;
        img {
          width: 200px;
          height: 300px;
          max-width: 100%;
        }
      }
      &:first-child {
        padding-right: 0;
        padding-top: 0;
        width: 100%;
      }
    }
  }
`;

const SImgOuter = styled.div`
  width: 100%;
  height: 500px;
  box-shadow: var(--uicard-shadow-deep);
  transform: translateX(calc(var(--side-margin) / 2));
  @media (max-width: 800px) {
    transform: translateY(calc(var(--side-margin) / -1));
    width: 60%;
    height: 350px;
  }
`;

const SFingerprint = styled.div`
  width: 200px;
  height: 200px;
  background: url(/graphics/fingerprint.png) center/contain;
  position: absolute;
  margin-top: 0px;
  margin-left: -100px;
  mix-blend-mode: overlay;
  filter: var(--svgFilter);
`;

/**
 */
const CommunityPost = ({ data }) => {
  const post = data?.graphCmsInterview;
  const quote = post?.quote;
  return (
    <Page
      twitter={post?.people?.[0]?.twitter}
      date={post?.date}
      type="article"
      title={`${post?.title} | Catch Community`}
      description={post?.summary}
      image={post?.image?.gatsbyImageData}
      color="#F4F3F7"
      darkColor="#262626"
    >
      <Section bg="var(--c-bg-gray)">
        <SPost>
          <div>
            <eb>Community</eb>
            <h1>{post?.title}</h1>
            <h3>{quote?.body}</h3>
          </div>
          <div>
            <SFingerprint />
            <SFingerprint />
            <SImgOuter>
              <GatsbyImage image={post?.image?.gatsbyImageData} style={{ borderRadius: 24 }} />
            </SImgOuter>
          </div>
        </SPost>
      </Section>
      <Section small narrow>
        <article className="article">
          <Markdown>{post?.body}</Markdown>
        </article>
      </Section>
    </Page>
  );
};

// export async function config() {
//   return ({ params }) => {
//     return {
//       defer: true,
//     };
//   };
// }

export default CommunityPost;
